/**********************************************************
 
 S O R T  A N D  F O R M A T  S T R I N G  V A L U E S
 
 ***********************************************************/

export const coerceToNumber = (data, keys) => {
  return data.map(item => {
    const updatedItem = { ...item }
    keys.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(updatedItem, key)) {
        updatedItem[key] = parseFloat(updatedItem[key])
      }
    })
    return updatedItem
  })
}
/**********************************************************
   
   S O R T  A N D  F O R M A T  S T R I N G  V A L U E S
   
***********************************************************/

export const sortStringAndFormat = (data, key) => {
  const seen = new Set()
  const sortedData = data
    // sort in alphabetical order
    .sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()))
    // filter out duplicates
    .filter(entry => {
      const duplicate = seen.has(entry[key])
      seen.add(entry[key])
      return !duplicate
    })

  const sortedList = []
  sortedData.map((entry, index) => {
    sortedList.push({
      id: index + 1,
      name: entry[key],
    })

    return sortedList
  })
  return [sortedList]
}

/**********************************************************
   
   S O R T  A N D  F O R M A T  G A M E  W E E K S
   
***********************************************************/

export const sortAndFormatGameWeeks = data => {
  const seen = new Set()
  const sortedData = data
    // sort in numerical order
    .sort((a, b) => a.gw - b.gw)
    // filter out duplicates
    .filter(entry => {
      const duplicate = seen.has(entry.gw)
      seen.add(entry.gw)
      return !duplicate
    })

  const sortedList = []
  sortedData.map(entry => {
    sortedList.push({
      value: `GW${entry.gw}`,
      label: `GW${entry.gw}`,
    })

    return sortedList
  })
  return [sortedList]
}
/**********************************************************
   
   S O R T  A N D  F O R M A T  M A N A G E R S
   
***********************************************************/

export const sortAndFormatManagers = data => {
  const seen = new Set()
  const sortedData = data
    // sort in alphabetical order
    .sort((a, b) => a.manager.toLowerCase().localeCompare(b.manager.toLowerCase()))
    // filter out duplicates
    .filter(entry => {
      const duplicate = seen.has(entry.manager)
      seen.add(entry.manager)
      return !duplicate
    })

  const sortedList = []
  sortedData.map(entry => {
    sortedList.push({
      value: entry.manager,
      label: entry.manager,
    })

    return sortedList
  })
  return [sortedList]
}

/****************************************************************************
   
   C O M B I N E  I N  A N D  O U T  P L A Y E R S  I N T O  O N E  L I S T 
   
*****************************************************************************/

export const combinePlayers = data => {
  const combinedList = []
  data.map(entry => {
    combinedList.push({
      player: entry.fullNameIn,
    })
    combinedList.push({
      player: entry.fullNameOut,
    })

    return combinedList
  })
  return [combinedList]
}
/********************************
   
   S O R T I N G  F U N C T I O N S
   
********************************/

export const sortDataFn = (sortedData, setSortedData, value = 'auctionAsc') => {
  let sorted
  if (value === 'auctionAsc') {
    sorted = [...sortedData].sort((a, b) => b.auctionSpend - a.auctionSpend)
  } else if (value === 'auctionDesc') {
    sorted = [...sortedData].sort((a, b) => a.auctionSpend - b.auctionSpend)
  } else if (value === 'plAsc') {
    sorted = [...sortedData].sort((a, b) => b.pandl - a.pandl)
  } else {
    sorted = [...sortedData].sort((a, b) => a.pandl - b.pandl)
  }
  setSortedData(sorted)
}

/***************************************************************************
   
   C H E C K  I F  O B J E C T  H O L D S  A N Y  T R U T H Y  V A L U E S
   
***************************************************************************/

export const checkForTruth = obj => {
  return Object.values(obj).some(value => value)
}

/***************************************************************************
   
   F I L T E R  A R R A Y  B Y  K E Y S
   
***************************************************************************/

export const filterByKeys = (data, keysToInclude) => {
  return data
    .map(obj => {
      const newObj = {}
      Object.entries(obj).forEach(([key, value]) => {
        if (keysToInclude.includes(key)) {
          newObj[key] = value
        }
      })
      return newObj
    })
    .filter(obj => {
      return Object.values(obj).some(val => val)
    })
}

/***************************************************************************
   
   A S S I G N  L A B E L S  T O  F I L T E R  R A D I O S
   
***************************************************************************/

export const assignRadioLabel = val => {
  if (val === 'auctionAsc') {
    return ['Auction Asc']
  } else if (val === 'auctionDesc') {
    return ['Auction Desc']
  } else if (val === 'plAsc') {
    return ['P&L Asc']
  } else if (val === 'plDesc') {
    return ['P&L Desc']
  } else {
    return []
  }
}
export const assignRadioFull = val => {
  if (val === 'auctionAsc') {
    return 'ascending auction values'
  } else if (val === 'auctionDesc') {
    return 'descending auction values'
  } else if (val === 'plAsc') {
    return 'ascending P&L values'
  } else if (val === 'plDesc') {
    return 'descending P&L values'
  } else {
    return ''
  }
}
